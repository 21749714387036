import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getting-started"
    }}>{`Getting Started`}</h1>
    <p>{`Design systems enable teams to build better products faster by making design reusable—reusability makes scale possible. This is the heart and primary value of design systems. A design system is a collection of reusable components, guided by clear standards, that can be assembled together to build any number of applications.`}</p>
    <p>{`Regardless of the technologies and tools behind them, a successful design system follows these guiding principles:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`It’s consistent`}</strong>{`. The way components are built and managed follows a predictable pattern.`}</li>
      <li parentName="ul"><strong parentName="li">{`It’s self-contained`}</strong>{`. Your design system is treated as a standalone dependency.`}</li>
      <li parentName="ul"><strong parentName="li">{`It’s reusable`}</strong>{`. You’ve built components so they can be reused in many contexts.`}</li>
      <li parentName="ul"><strong parentName="li">{`It’s accessible`}</strong>{`. Applications built with your design system are usable by as many people as possible, no matter how they access the web.`}</li>
      <li parentName="ul"><strong parentName="li">{`It’s robust`}</strong>{`. No matter the product or platform to which your design system is applied, it should perform with grace and minimal bugs.`}</li>
    </ul>
    <h2 {...{
      "id": "consistency"
    }}>{`Consistency`}</h2>
    <p>{`Your first, most important task when starting out is to define the rules of your system, document them, and ensure that everyone follows them. When you have clearly documented code standards and best practices in place, designers and developers from across your organization can easily use and, more importantly, contribute to your design system.`}</p>
    <h1 {...{
      "id": "getting-started-1"
    }}>{`Getting Started`}</h1>
    <p>{`Design systems enable teams to build better products faster by making design reusable—reusability makes scale possible. This is the heart and primary value of design systems. A design system is a collection of reusable components, guided by clear standards, that can be assembled together to build any number of applications.`}</p>
    <p>{`Regardless of the technologies and tools behind them, a successful design system follows these guiding principles:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`It’s consistent`}</strong>{`. The way components are built and managed follows a predictable pattern.`}</li>
      <li parentName="ul"><strong parentName="li">{`It’s self-contained`}</strong>{`. Your design system is treated as a standalone dependency.`}</li>
      <li parentName="ul"><strong parentName="li">{`It’s reusable`}</strong>{`. You’ve built components so they can be reused in many contexts.`}</li>
      <li parentName="ul"><strong parentName="li">{`It’s accessible`}</strong>{`. Applications built with your design system are usable by as many people as possible, no matter how they access the web.`}</li>
      <li parentName="ul"><strong parentName="li">{`It’s robust`}</strong>{`. No matter the product or platform to which your design system is applied, it should perform with grace and minimal bugs.`}</li>
    </ul>
    <h2 {...{
      "id": "consistency-1"
    }}>{`Consistency`}</h2>
    <p>{`Your first, most important task when starting out is to define the rules of your system, document them, and ensure that everyone follows them. When you have clearly documented code standards and best practices in place, designers and developers from across your organization can easily use and, more importantly, contribute to your design system.`}</p>
    <h1 {...{
      "id": "getting-started-2"
    }}>{`Getting Started`}</h1>
    <p>{`Design systems enable teams to build better products faster by making design reusable—reusability makes scale possible. This is the heart and primary value of design systems. A design system is a collection of reusable components, guided by clear standards, that can be assembled together to build any number of applications.`}</p>
    <p>{`Regardless of the technologies and tools behind them, a successful design system follows these guiding principles:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`It’s consistent`}</strong>{`. The way components are built and managed follows a predictable pattern.`}</li>
      <li parentName="ul"><strong parentName="li">{`It’s self-contained`}</strong>{`. Your design system is treated as a standalone dependency.`}</li>
      <li parentName="ul"><strong parentName="li">{`It’s reusable`}</strong>{`. You’ve built components so they can be reused in many contexts.`}</li>
      <li parentName="ul"><strong parentName="li">{`It’s accessible`}</strong>{`. Applications built with your design system are usable by as many people as possible, no matter how they access the web.`}</li>
      <li parentName="ul"><strong parentName="li">{`It’s robust`}</strong>{`. No matter the product or platform to which your design system is applied, it should perform with grace and minimal bugs.`}</li>
    </ul>
    <h2 {...{
      "id": "consistency-2"
    }}>{`Consistency`}</h2>
    <p>{`Your first, most important task when starting out is to define the rules of your system, document them, and ensure that everyone follows them. When you have clearly documented code standards and best practices in place, designers and developers from across your organization can easily use and, more importantly, contribute to your design system.`}</p>
    <h1 {...{
      "id": "getting-started-3"
    }}>{`Getting Started`}</h1>
    <p>{`Design systems enable teams to build better products faster by making design reusable—reusability makes scale possible. This is the heart and primary value of design systems. A design system is a collection of reusable components, guided by clear standards, that can be assembled together to build any number of applications.`}</p>
    <p>{`Regardless of the technologies and tools behind them, a successful design system follows these guiding principles:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`It’s consistent`}</strong>{`. The way components are built and managed follows a predictable pattern.`}</li>
      <li parentName="ul"><strong parentName="li">{`It’s self-contained`}</strong>{`. Your design system is treated as a standalone dependency.`}</li>
      <li parentName="ul"><strong parentName="li">{`It’s reusable`}</strong>{`. You’ve built components so they can be reused in many contexts.`}</li>
      <li parentName="ul"><strong parentName="li">{`It’s accessible`}</strong>{`. Applications built with your design system are usable by as many people as possible, no matter how they access the web.`}</li>
      <li parentName="ul"><strong parentName="li">{`It’s robust`}</strong>{`. No matter the product or platform to which your design system is applied, it should perform with grace and minimal bugs.`}</li>
    </ul>
    <h2 {...{
      "id": "consistency-3"
    }}>{`Consistency`}</h2>
    <p>{`Your first, most important task when starting out is to define the rules of your system, document them, and ensure that everyone follows them. When you have clearly documented code standards and best practices in place, designers and developers from across your organization can easily use and, more importantly, contribute to your design system.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      